@import "bootstrap/scss/bootstrap-reboot.scss";
@import "bootstrap/scss/bootstrap-grid.scss";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/alert";

@import "scss/base/variables";
@import "scss/base/typography";
@import "scss/layout/default";


div.header {
	div.navigation {
		ul {
			list-style: none;
			padding: 0;
			margin: 68px 0 -5px;
			border: none;

			li {
				display: inline-block;
				margin-right: 2%;
				padding: 5px 10px 0;
				border: none;

				a {
					border-bottom: 5px solid transparent;
					&:hover {
						color: $linkcolor;
						text-decoration: none;
						border-bottom-color: $linkcolor;
					}
				}
			}
		}
	}
}


table {
	width: 100%;
  margin-bottom: 15px;

  td {
    &.period {
      min-width: 150px;
      vertical-align: top;
    }

    > ul {
      margin-left: 0;
      padding-left: 0;
      list-style: circle;
      & li {
        // margin-left;

      }
    }
  }
}

div.mededeling {
  font-weight: bold;
  margin-bottom: 15px;
  border: 1px solid $green;
  padding: 5px;
  background-color: #f9f6f6;
}
