
/* latin-ext */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src:
		local('Lato Regular'),
		local('Lato-Regular'),
		url(./fonts/Lato-Regular.ttf) format('truetype');

	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 400;
	src:
		local('Lato Regular'),
		local('Lato-Regular'),
		url(./fonts/Lato-Regular.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
  /* latin-ext */
  @font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src:
		local('Lato Bold'),
		local('Lato-Bold'),
		url(./fonts/Lato-Bold.ttf) format('truetype');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Lato';
	font-style: normal;
	font-weight: 700;
	src:
		local('Lato Bold'),
		local('Lato-Bold'),
		url(./fonts/Lato-Bold.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Overlock';
	font-style: normal;
	font-weight: 400;
	src:
		url(./fonts/Overlock-Regular.ttf) format('truetype');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Overlock';
	font-style: normal;
	font-weight: 400;
	src:
		url(./fonts/Overlock-Regular.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Overlock';
	font-style: normal;
	font-weight: 700;
	src:
		url(./fonts/Overlock-Bold.ttf) format('truetype');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Overlock';
	font-style: normal;
	font-weight: 700;
	src:
		url(./fonts/Overlock-Bold.ttf) format('truetype');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }


body {
	font-family: 'Lato', sans-serif;
	font-style: normal;
	font-weight: normal;
	color: #46454d;
	background-color: #f6f6f5;
}

h1, h2, h3, h4 {
	font-family: 'Overlock', sans-serif;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 0.05rem;
}

h1 {
  font-size: 1.8rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.3rem;
}

a {
  text-decoration: none;
}

.small {
	font-size: 85%;
}
