body {
  background-color: $background;
  color: $fontcolor;
}

.logo-wrapper {
  position: relative;
  text-align: center;

  img {
    position: absolute;
    max-width: 50%;
    top: 0;
    left: 0;
    margin: 15% 25%;
  }
}

.profile {
  text-align: center;
}


.profile-image {
  max-width: 80%;
  margin: 2% 10%;

  img {
    // border-radius: 15px;
    width: 100%;
  }
}

.header {
  min-height: 100px;
  background-color: $lightblue;
  border-bottom: 5px solid $orange;

  &.row {
    flex-wrap: nowrap;
  }
}

.content {
  margin-top: 50px;
}

dl {
  dt {
    display: none;
  }
}

.container-fluid {
  max-width: 1360px;
}

.main {
  margin-bottom: 100px;
}

a,
a:active,
a:visited,
.green {
  color: $linkcolor;
}

a:hover {
  color: $altgreen;
}

.footer {
  margin-top: 25px;
  background-color: $green;
  min-height: 50px;
  padding: 15px 20px;


  a,
  a:active,
  a:visited,
  a:hover {
    color: $darkgrey;
  }
}


// .text-right {
//   text-align: right;
// }


