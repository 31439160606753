// colors

$bluegrey: #65747b;
$green: #738318;
$darkgrey: #46454d;
$offwhite: #f0eceb;
$orange: #c66629;
$altgreen: #b4c539;
$lightblue: #cccbc5;

$background: $offwhite;
$fontcolor: $darkgrey;
$linkcolor: $green;
